.contentPortfolio .simplebar-track.simplebar-vertical {
  right: 7px !important;
}

.contentPortfolio .builderToggleGroup {
  font-size: 13px;
}
.submitBasketCalendar {
  margin-left: 4%;
}

.submitBasketCalendar .CalendarDate div:nth-child(1) {
  color: #ffffff !important;
  font-weight: 400 !important;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.content-tabs-bulksbasket .react-tabs__tab {
  border-radius: 5px !important;
  margin: 5px;
  margin-bottom: 10px;
  font-weight: bold;
}

.content-tabs-bulksbasket .bitaTabs table td:nth-child(1) {
  background: transparent !important;
  width: 120px;
  font-weight: 400;
}

.content-tabs-bulksbasket .bitaTabs table td:nth-child(2) {
  background: transparent !important;
}

.content-tabs-bulksbasket .bitaTabs table td,
.content-tabs-bulksbasket .bitaTabs table tr {
  border: none;
}

.content-tabs-bulksbasket .bitaTabs table td,
.content-tabs-bulksbasket .bitaTabs table th {
  width: 80px;
  text-align: center;
}

.contentTableBasketPreview .content-Widget {
  padding: 0px !important;
}

.headerTable {
  border-bottom: 0.5px solid hsla(0, 0%, 100%, 0.4);
  padding: 10px 0;
  margin-bottom: 10px;
}

.contentTableBasketPreview th,
.tableUpdateBasket th {
  position: sticky;
  background: #3b3f44;
  z-index: 9999999;
}

.contentTableBasketPreview td {
  max-width: 25%;
  padding-right: 30px;
}
.needValidationSelect .react-datepicker-input {
  border: 1px solid #c93a3a !important;
}

.contentTableBasketPreview h1 {
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
}
.titleInfo {
  margin-right: 35px;
  margin-top: 20px;
}
.custom-row-container {
  position: relative;
}

.icon {
  padding-right: 20px;
  padding-top: 8px;
}
.info-icon-live {
  padding-right: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.info-icon-live {
  padding-right: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.icon-info {
  font-size: 20px;
  color: rgb(46, 235, 46);
}

.icon-error {
  font-size: 20px;
  color: yellow;
}

.tooltip-icon {
  margin-right: 5px;
  font-size: 20px;
}

.tooltip {
  display: none;
  position: absolute;
  width: 280px;
  left: -240px;
  border-radius: 5px;
  text-wrap: wrap;
  background-color: #f7f7f7;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
  padding: 3px 8px;
  color: #333333;
  z-index: 999999999;
}

.tooltip .icon-error {
  color: red;
}
.tooltip .icon-info {
  color: green;
}

.info-icon-live:hover ~ .tooltip {
  display: flex;
  color: #333333;
  background: #f7f7f7;
  opacity: 0.85;
}
