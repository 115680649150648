@import '../../sass/Variables.scss';

.bitaTableContainer {
  width: 100%;
  max-height: 100%;
}

.tableTitleContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #ffffff66;
  padding: 10px 0;
  margin-bottom: 10px;
}

.tableTitle {
  font-size: 16px;
  font-weight: $font-weight-semibold;
  color: #fff !important;
  letter-spacing: 2.58px;
  line-height: 24px;
  text-transform: uppercase;
}

.tableFirstrow {
  float: left;
}

.bitaTable {
  min-width: 100%;
  color: $input-text-color;
  table-layout: fixed;
  white-space: nowrap;
}

.bitaTableHeaderRow {
  width: 100%;
  font-size: 10px;
  font-weight: $font-weight-semibold;
  color: #fff !important;
  line-height: 24px;
  text-transform: uppercase;
  text-align: left;
  th:nth-child(n) {
    top: 0;
  }
}

.bitaTableRowContent {
  float: left;
  width: 100%;
  max-width: 380px;
}

.searchContainer {
  margin-top: -10px;
  svg {
    position: relative;
    top: 20px;
  }
}

.searchInput {
  width: 217px;
  height: 23px;
  font-size: 12px;
  padding-left: 20px;
  border-bottom: solid 1px $input-bottom-border-color;
}

.bitaTableContentColumn td {
  padding-bottom: 10px;
}
.bitaTableContentColumn td:nth-child(3) {
  padding-left: 5px;
}
.rowSecondaryTheme:nth-child(1) {
  padding-left: 25px;
}
.rowSecondaryTheme {
  height: 33px;
  border-bottom: 1px solid;
}

.headerSecondaryTheme:nth-child(1) {
  padding-left: 25px;
}
.headerSecondaryTheme {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.31px;
  line-height: 28px;
  text-align: justify;
  svg {
    display: initial !important;
    margin-left: 5px;
  }
}

.selectHeadersButton {
  position: absolute;
  height: 30px;
  width: 20px;
  background-color: #fff;
  color: black;
}

.dropdownContainer {
  position: absolute;
  background: white;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  color: black;
  text-align: center;
  margin-top: 5px;
}

.dropdownContent {
  background-color: white;
  width: 200px;
  border-radius: 2px;
}

.bitaTableScrollable {
  height: 550px;
  padding: 0 5px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.headerPrimaryTheme {
  svg {
    display: initial !important;
    margin-left: 5px;
  }
  th {
    width: 10%;
  }
  th:nth-child(1) {
    width: 35%;
  }
  th:nth-child(2) {
    width: 15%;
    max-width: 150px;
  }
  th:nth-last-child(3) {
    width: 10% !important;
  }
}

.tr .rowicon {
  display: none;
}

.td {
  font-size: 12px;
  color: white;
  width: 10%;
}

td:nth-child(1) {
  width: 38%;

  span {
    width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  svg {
    width: 40px !important;
    height: 40px !important;
  }
  span:hover {
    background: #3b3f44 !important;
    width: initial;
    position: absolute;
    white-space: nowrap;
    text-overflow: unset;
    overflow: unset;
    display: flex;
    align-items: center;
    margin-left: 50px;
    margin-right: 90px;
    padding-top: 10px;
    padding-right: 5px;
    margin-top: -5px;
  }
}
