.headerpath {
  background: #2962ff;
  color: #fff;
  font-weight: bold;
  font-size: 21px;
  margin-left: 10px;
  display: inline-block;
  padding: 14px 10px;
  border-radius: 4px;
  margin-right: 0px;
  height: 50px;
}

.dataUpload {
  background: #3b3f44 !important;
  padding: 18px 18px 18px 0px;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  > div {
    padding-left: 18px;
  }

  .contentTemplate {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
}
.viewData {
  font-size: 12px;
  background: #a7a7aa;
}
.subtitle {
  width: 100%;
  color: #2962ff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 11px;
  display: flex;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  outline-style: none !important;
  background-color: #fff;
  color: #2962ff;
  border-radius: 2.55px;
  font-size: 12px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  letter-spacing: 1.37px;
  font-weight: 500;
  width: 160px !important;
  height: 35px !important;
  padding: 8px 44px;
  margin: 0 15px 0px 0px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-image: linear-gradient(90deg, #ffffff 0%, #d5d5d5 97%, #d4d4d4 100%);
  border-radius: 3.15px;
}
.nameFile {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #a7a7aa;
  font-size: 13px;
  margin-left: 10px;
  margin-top: 10px;
}
.generatedTable {
  margin-bottom: 10px;
  border-radius: 5px;
  th,
  tr,
  td {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid;
  }
}

.generatedTableValues {
  border-bottom: 1px solid;
}
.content {
  height: calc(100% - 60px);
  background-color: #2a2d31 !important;
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 25px;
}

.contentWidget {
  display: flex;
  position: relative;
  flex-flow: column;
  background: #3b3f44 !important;
  padding: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  > div:nth-child(1) {
    border-bottom: 1px solid #c7c7c7;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
}

.bulkContainterTitleTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px !important;
  margin-bottom: -20px !important;
  padding: 0 !important;
  border: none !important;
}

.contentInput {
  > div {
    margin-left: 0px !important;
    width: 110px;
  }
  align-items: end;
  margin-bottom: 15px;
}
.contentInputCash {
  > div {
    margin-left: 0px !important;
    width: 160px;
  }
  align-items: end;
  margin-bottom: 15px;
}
.basketInput {
  margin-left: 0px;
  width: 100px;
  color: #fff;
  border: none;
  border-bottom: 1px solid #c7c7c7;
  letter-spacing: 1.54px;
  padding-left: 5px;
  background: #4b5158 !important;
  font-size: 13px;
  height: 35px;
}

.basketInputCash {
  margin-left: 0px;
  width: 150px;
  color: #fff;
  border: none;
  border-bottom: 1px solid #c7c7c7;
  letter-spacing: 1.54px;
  padding-left: 5px;
  background: #4b5158 !important;
  font-size: 13px;
  height: 35px;
}

.modalTextTitle {
  text-align: center;
  font-size: 25px;
  margin: 20px auto;
}

.contentDataUpload {
  display: flex;
  flex-wrap: wrap;
  > div:nth-child(1) {
    width: 34%;
  }
  > div:nth-child(2) {
    width: 65%;
  }
}

.contentUpdateBasket {
  width: 60% !important;
}

.contentTemplate {
  max-width: 560px;
  border: 2px solid gray;
  padding: 18px;
  margin-right: 18px;
}

.tableNoMetadata {
  margin-top: 20px;
  font-size: 10px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    > div:nth-child(1) {
      text-transform: uppercase;
      font-weight: bold;
    }
    > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
.csvReader {
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  outline-style: none !important;
  background-image: linear-gradient(270deg, #2962ff 0%, #0039cb 100%);
  color: #fff;
  border-radius: 2.55px;
  font-size: 12px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  letter-spacing: 1.37px;
  font-weight: 500;
  text-transform: capitalize;
  width: 43% !important;
  height: 28px !important;
  padding: 7px 54px;
  margin: 0px;
}

.needValidation {
  margin-left: 0px;
  width: 100px;
  border: none;
  border: 1px solid rgb(201, 58, 58) !important;
  letter-spacing: 1.54px;
  padding-left: 5px;
  background: #4b5158 !important;
  font-size: 13px;
  height: 35px;
  /* &::placeholder { color: rgb(201, 58, 58)!important;  } */
}

.needValidationCash {
  margin-left: 0px;
  width: 150px;
  border: 1px solid rgb(201, 58, 58) !important;
  letter-spacing: 1.54px;
  padding-left: 5px;
  background: #4b5158 !important;
  font-size: 13px;
  height: 35px;
}

.needValidationSelect {
  border: 2px solid rgb(201, 58, 58) !important;
  /* &::placeholder { color: rgb(201, 58, 58)!important;  } */
}

.nameInput {
  width: 700px;
  margin-left: 0px !important;
  color: #fff;
  border: none;
  border-bottom: 1px solid #c7c7c7;
  letter-spacing: 1.54px;
  background: #4b5158 !important;
  font-size: 13px;
  height: 35px;
  margin-bottom: 20px;
}

.cashConstituentsInput {
  width: 150px;
  margin-left: 0px !important;
  color: #fff;
  border: none;
  border-bottom: 1px solid #c7c7c7;
  letter-spacing: 1.54px;
  background: #4b5158 !important;
  font-size: 13px;
  height: 35px;
  margin-bottom: 20px;
}

.requestCoverageEntry {
  display: flex;
  justify-content: flex-start !important;
  div {
    width: 40% !important;
  }
}

.containerButtonsUpload {
  display: flex;
  margin-top: 10px;
  align-items: flex-end;
  flex-wrap: wrap;
  > div,
  > button {
    margin-top: 10px;
  }
}

@media (max-width: 1797px) {
  /* Estilos que se aplicarán cuando el ancho de la pantalla sea 1797px o menos */
  .contentTemplate {
    max-width: 380px;
  }
}

@media (max-width: 1391px) {
  /* Estilos que se aplicarán cuando el ancho de la pantalla sea 1391px o menos */
  .contentTemplate {
    max-width: 200px;
  }
  .contentUpdateBasket {
    width: 100% !important;
  }

  .nameInput {
    width: 400px;
    margin-left: 0px !important;
    color: #fff;
    border: none;
    border-bottom: 1px solid #c7c7c7;
    letter-spacing: 1.54px;
    background: #4b5158 !important;
    font-size: 13px;
    height: 35px;
    margin-bottom: 20px;
  }
}

@media (max-width: 705px) {
  .nameInput {
    width: 200px;
    margin-left: 0px !important;
    color: #fff;
    border: none;
    border-bottom: 1px solid #c7c7c7;
    letter-spacing: 1.54px;
    background: #4b5158 !important;
    font-size: 13px;
    height: 35px;
    margin-bottom: 20px;
  }
}
