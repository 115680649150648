.dataUpload {
  height: calc(100% - 20px);
}

.dataUpload > button {
  float: right;
  margin-top: -40px;
}

.dataUpload .contentBotones {
  position: relative;
}

.dataUpload .contentBotones > button {
  margin-right: 0px !important;
}

.dataUpload label {
  padding: 9.5px;
  border-radius: 5px;
  position: absolute;
  height: 35px !important;
}

.modaldataUpload {
  width: 70vw;
  height: 80vh;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.modaldataUpload > button {
  margin-left: 37%;
}

.modaldataCreateUpload .builderInputComponentContainer,
.modaldataCreateUpload .builderInputComponentContainer div,
.modaldataCreateUpload .builderInputComponentContainer div div,
.modaldataCreateUpload .builderInputComponentContainer div div div,
.modaldataCreateUpload .builderInputComponentContainer input {
  width: 100%;
}

.modaldataCreateUpload .contentaddibleRows {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.modaldataCreateUpload .simplebar-track.simplebar-vertical {
  right: 10px !important;
}
.modaldataCreateUpload .builderInputComponentContainer div {
  margin-left: 0px;
}
.modaldataCreateUpload .contentCreateUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  height: calc(100% - 100px);
}

.contentCreateUpload > div:nth-child(2) {
  justify-content: flex-start !important;
  margin-top: 15px;
}

.modaldataCreateUpload .addibleRows {
  margin-top: 15px;
  height: 280px;
}

.contentCreateUpload .addibleRows .dropdownField,
.contentCreateUpload .addibleRows .addibleRowRemoveButton,
.contentCreateUpload .addibleRows .builderRatioButtonsOptions {
  margin-top: 17px;
}

.titleItemField {
  text-transform: uppercase;
}

#inputSearchModalCustomField {
  width: 180px;
  background-image: url('../../static/img/Icons/search.svg');
  background-repeat: no-repeat;
  background-position: 0px 3px;
  padding-left: 15px;
  font-size: 12px;
}

.modaldataCreateUpload .addibleRows .builderInputComponentContainer,
.modaldataCreateUpload .addibleRows .builderInputComponentContainer div,
.modaldataCreateUpload .addibleRows .builderInputComponentContainer div div,
.modaldataCreateUpload .addibleRows .builderInputComponentContainer div div div,
.modaldataCreateUpload .addibleRows .builderInputComponentContainer input {
  width: 100px;
  margin-bottom: 0px !important;
}

.modaldataCreateUpload .builderInputComponentContainer span {
  color: #2962ff;
  text-transform: uppercase;
  font-weight: 600;
}

.modaldataCreateUpload .dropdownField {
  width: 280px;
}

.modaldataCreateUpload .dropdownField > div {
  width: 280px !important;
}

.modaldataUpload .content {
  height: calc(100% - 180px);
  width: 100%;
}

.contentFields > button {
  margin-top: 10px;
}

.modaldataUpload .itemField {
  border-radius: 6px;
  background: #4b5158;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 12px;
}

.modaldataUpload .itemField button {
  margin-right: 7px;
}

.modaldataUpload .itemField .contentColumn > div:nth-child(1) {
  width: 40% !important;
}

.contentFields {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.close {
  width: 10px;
  position: relative;
  left: 99%;
  margin-top: -17px;
  font-weight: 500;
  font-size: 30px;
  cursor: pointer;
  font-weight: bold;
}
.titleModal {
  width: 100%;
  color: #ffffff;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 2.97px;
  line-height: 23px;
  display: flex;
  align-items: center;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px solid gray;
  margin-bottom: 15px;
  text-transform: upperCase;
}

.titleComponente {
  text-transform: upperCase;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.titleComponente svg {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.titleModal svg {
  margin-right: 10px;
}

.UserManagement {
  color: white;
}
.UserManagement .contentUser {
  display: flex;
  width: calc(100% - 15px);
  align-items: center;
  justify-content: space-between;
  background: #575a5f;
  border-radius: 8px;
  padding: 12px 40px 12px 12px;
  margin-bottom: 10px;
}

.contentUser .userName {
  flex-shrink: 0;
  flex-grow: 0;
  width: 30%;
}

.UserManagement .contentUser .icon {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background: linear-gradient(270deg, #2962ff 0%, #0039cb 100%);
  border-radius: 5px;
  margin-right: 10px;
}

.contentBotones {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.buttonUpload {
  margin-bottom: 20px;
  position: relative;
  top: -75px;
  left: 280px;
}

.search {
  display: flex;
}

.search .iconSearch {
  margin-right: 10px;
}

.contentManager {
  background: #575a5f !important;
}

.contentManager .tablemodal .content-Widget {
  background: transparent !important;
  margin: 0px !important;
  padding: 0px !important;
}
.contentManager .tablemodal > div > div:nth-child(1) {
  display: none;
}
.contentManager .message {
  text-align: center;
  margin-top: 13%;
}
.contentManager .optionAction {
  text-transform: capitalize;
}

.contentManager .tablemodal td:nth-child(1) {
  text-transform: uppercase;
}

.contentManager .contentManagericons svg {
  margin-right: 10px;
  width: 46px;
  min-width: 46px;
  height: 46px;
}

.contentManagericons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contentManagericons > div {
  width: 25%;
  text-align: center;
}
.contentManagericons div:nth-last-child(4) {
  width: 25%;
  text-align: justify;
  text-transform: uppercase;
}
.contentManager tbody tr:hover {
  background-color: cornflowerblue;
}
.contentManager tbody tr span:hover {
  background-color: cornflowerblue !important;
}

.UserManagementTitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.contentItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 10px;
}
.contentItem > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentItem > div:nth-child(1) {
  font-weight: 600;
  font-weight: 600;
  justify-content: flex-start;
  width: 100%;
}
.contentItem > div:nth-child(1) > div {
  text-overflow: clip;
  word-break: break-word;
  overflow-wrap: break-word;
}

.contentItem .icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background: white;
  color: #0039cb;
  border-radius: 5px;
  margin-right: 10px;
}

.selectionMatrixContainer .headings,
.selectionMatrixContainer .selectionMatrixRow {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c7c7c7;
  padding: 10px 0;
}

.selectionMatrixContainer .headings {
  font-weight: 600;
  text-transform: uppercase;
}

.selectionMatrixContainer .headings > div:first-child,
.selectionMatrixContainer .selectionMatrixRow > div:first-child {
  flex-shrink: 0;
  flex-grow: 0;
  width: 25%;
  display: flex;
  justify-content: flex-start;
}

.selectionMatrixContainer .headings > div,
.selectionMatrixContainer .selectionMatrixRow > div {
  width: 25%;
  display: flex;
  justify-content: center;
}
.selectionMatrixContainer .selectionMatrixRow:nth-last-child(1) {
  opacity: 0.35;
}

.modalExcludeIsin {
  height: 410px;
  width: 90vh;
}

.modalExcludeIsin .contentModalExcludeIsin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
}

.modalExcludeIsin .contentModalExcludeIsin svg {
  width: 25px;
  height: 20px;
  cursor: pointer;
}

.modalExcludeIsin .contentModalExcludeIsin button {
  width: 180px !important;
}
.modalExcludeIsin .contentModalExcludeIsin > div {
  margin-bottom: 10px;
  width: 100%;
}

.isinContent {
  width: 100%;
  height: 225px;
  background: #575a5f;
  margin-bottom: 10px;
  border-radius: 5px;
}

.isinContent > .isins {
  display: flex;
  flex-wrap: wrap;
}

.isinContent p {
  height: 30px;
  padding: 10px;
  width: 20%;
}
.modaldataCreateUpload .addibleRows {
  margin-top: 15px;
  height: 280px;
}

@media (max-width: 3000px) {
  .modaldataCreateUpload .addibleRows {
    height: calc(100% - 200px);
  }

  .modaldataCreateUpload .addibleRows .addibleRow {
    align-items: flex-end;
  }

  .modaldataCreateUpload .addibleRows .addibleRow:nth-child(2) .dropdown__menu-list,
  .modaldataCreateUpload .addibleRows .addibleRow:nth-child(1) .dropdown__menu-list,
  .modaldataCreateUpload .addibleRows .addibleRow:nth-child(3) .dropdown__menu-list {
    position: relative;
    margin-top: 0px;
    max-width: 280px;
    font-weight: 500;
    padding: 0px !important;
    border-radius: 5px !important;
    padding: 0px !important;
    border: 1px solid white;
    opacity: 1 !important;
    background-color: #2a2d31;
    background: #2a2d31;
    z-index: 99999999999999999999999999;
  }

  .modaldataCreateUpload .addibleRows:nth-last-child(1) {
    margin-bottom: 100px;
  }

  .modaldataCreateUpload .addibleRows .dropdown__menu-list {
    z-index: 9999999999999999999999;
    max-height: 150px;
  }
}
@media (max-width: 1200px) {
  .buttonUpload {
    margin-bottom: 20px;
    position: static !important;
    margin-top: -10px;
  }
}

@media (max-width: 800px) {
  .UserManagement .contentUser {
    padding: 12px !important;
  }
  /* .modaldataCreateUpload .addibleRows, .modaldataCreateUpload .contentaddibleRows {
    height: 170px;
  } */
}
